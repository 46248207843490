import HTMLFlipBook from "react-pageflip";
import b1 from './assets/1.gif'
import b2 from './assets/2.gif'
import b3 from './assets/3.gif'
import b4 from './assets/4.gif'
import b5 from './assets/5.gif'
import b6 from './assets/6.gif'
import './App.css'

function App() {
  return (
    <div className="App">
      <HTMLFlipBook width={400} height={700}>
        <div className="demoPage">
          <img src={b1} alt="champion" style={{width: '400px'}}/>
        </div>

        <div className="demoPage">
          <img src={b2} alt="champion" style={{width: '400px'}}/>
        </div>
        <div className="demoPage">
          <img src={b3} alt="champion" style={{width: '400px'}}/>
        </div>
        <div className="demoPage">
          <img src={b4} alt="champion" style={{width: '400px'}}/>
        </div>
        <div className="demoPage">
          <img src={b5} alt="champion" style={{width: '400px'}}/>
        </div>
        <div className="demoPage">
          <img src={b6} alt="champion" style={{width: '400px'}}/>
        </div>
      </HTMLFlipBook>
    </div>
  );
}

export default App;
